body {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  max-width: 1440px;
  margin: auto;
}

.content {
  max-width: 1440px;
  margin: auto;
}

.custom-bg-green {
  background-color: #27750a;
}

.custom-bg-green-op {
  background-color: #27750a;
}

.custom-bg-yellow {
  background-color: #cebf09;
}

.custom-mid-green {
  color: #007f5f;
}

.custom-text-yellow {
  color: #cebf09;
}

.custom-text-green {
  color: #27750a;
}

.custom-border {
  border: 1px solid #27750a;
}

.custom-border-op2 {
  border: 0.5px solid #cebf09;
}

.custom-border-op {
  background-color: #27750a;
  color: #fff !important;
  transition: 500ms;
}

.custom-border-yellow {
  border: 2px solid #cebf09;
}

.custom-bot-nav {
  border: 2px solid #27750a;
  color: #27750a;
}

.custom-border-bot {
  border-bottom: 4px solid #cebf09;
}

.custom-border-bot2 {
  /* border-bottom: 4px solid #27750a; */
  border: none;
  font-weight: bold;
  background-color: #fff;
}

.custom-active {
  border-bottom: 2px solid #cebf09;
  font-weight: 600;
}

p,
a,
button,
input,
label,
div {
  font-family: "Montserrat", sans-serif;
}

input,
button {
  outline: none !important;
}

.head-a {
  margin: 0 auto;
  text-align: center;
}

.nanda {
  max-height: 150px;
}

.vfg {
  margin-left: auto;
}

.dsp {
  background: url(https://res.cloudinary.com/dudhmrdin/image/upload/v1605457458/Rectangle_225_1_m83yct.png);
}

.about-head {
  background: url(https://res.cloudinary.com/dudhmrdin/image/upload/v1611358634/Group_1316_vwgs2i-min_z0ab1y.png);
}

.tfh {
  background: url(https://res.cloudinary.com/dudhmrdin/image/upload/v1606723720/Group_1316_1_k1g0zz.png);
}

.cu {
  background: url(https://res.cloudinary.com/dudhmrdin/image/upload/v1607334394/Group_1399_2_me1cqk.png);
}

.ar-text {
  color: #cebf09;
}

.tfh-input::placeholder {
  color: #fff;
}

.trigger {
  width: 100%;
}

.Collapsible__trigger {
  display: block;
  font-weight: 400;
  text-decoration: none;
  color: #27750a;
  position: relative;
  border: 0.2px solid #27750a2e;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 25px;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
}

.Collapsible__trigger:after {
  font-family: "Font Awesome 5 Free";
  content: "\f107";
  position: absolute;
  right: 10px;
  top: 10px;
  display: block;
  transition: transform 300ms;
}

.Collapsible__trigger.is-open:after {
  transform: rotateZ(180deg);
}

.Collapsible__trigger.is-disabled {
  opacity: 0.5;
  background-color: grey;
}

.Collapsible__contentInner {
  margin: 20px 10px;
}

.hide-for-phone {
  display: none;
}

.custom-form {
  width: 700px;
}

.custom-input {
  border: 0.2px solid #27750a2e;
}

.s-content {
  border: none;
  outline: none;
}

.tab-btn {
  background-color: #fff;
}

.dropdown--head-button {
  cursor: pointer;
  border: none;
  outline: none;
  margin-left: 10px;
}

.dropdown--content {
  display: none;
  position: absolute;
  right: 0;
  background-color: #f9f9f9;
  min-width: 180px;
  box-shadow: 0px 5px 5px rgba(238, 192, 199, 0.3);
  z-index: 1;
  border: 1px rgba(242, 48, 80, 0.1) solid;
  border-radius: 8px;
}

.dropdown--content > a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  padding: 10px 15px;
  border-bottom: 1px solid rgba(105, 196, 72, 0.2);
  background: #fff;
  padding: 10px;
}

.dropdown--content > a:hover {
  background-color: #f1f1f1;
  background-color: rgba(105, 196, 72, 0.2);
}
.drop--down:hover > .dropdown--content {
  display: block;
}

.show-mob-menu {
  display: none;
}

.custom-left-width {
  border-left-width: 4px;
  border-color: #cebf09;
}

.date-res {
  display: none;
}

/* JUDGEMENT-OPTION-1-DETAIL--PAGE STYLES */
#judgement-option-1-detail--page #body .inner-container {
  margin: 0 3.02% 0 0.5%;
}

#judgement-option-1-detail--page #content {
  position: relative;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(199, 237, 187, 0.38);
  margin-top: 1.4375rem;
  border: none;
  border-radius: 5px;
  padding-bottom: 3px;
}

#judgement-option-1-detail--page #rows {
  position: sticky;
  z-index: 30;
}

#judgement-option-1-detail--page #title-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  padding: 1.125rem 4.5625rem;
  border-bottom: 1px solid #e5e5e5;
}
@media only screen and (max-width: 1000px) {
  #judgement-option-1-detail--page #title-row {
    padding: 1.125rem 0.5625rem;
  }
}

#judgement-option-1-detail--page .section-body-row p {
  color: #000000;
}

#judgement-option-1-detail--page #title {
  color: #27750a;
  font-size: 1.5rem;
  font-weight: 700;
}

#judgement-option-1-detail--page .btn {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: #27750a;
  color: #fff;
  font-size: 0.875rem;
  padding: 0.65rem 1.25rem;
  border: none;
  border-radius: 5px;
}

#judgement-option-1-detail--page .btn span {
}

#judgement-option-1-detail--page #button-extension {
  position: absolute;
  top: 44px;
  left: 0;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

#judgement-option-1-detail--page .d-none {
  display: none;
}

#judgement-option-1-detail--page .extension-row {
  padding: 0.75rem 0;
  text-align: center;
  color: #69c448;
  border-bottom: 1px solid #dadada;
}

#judgement-option-1-detail--page #nav-row {
  background-color: #ffffff;
  padding: 1.125rem 4.5625rem;
  border-bottom: 1px solid #e5e5e5;
}

#judgement-option-1-detail--page #nav-row ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#judgement-option-1-detail--page #content-body {
  height: 100%;
  display: flex;
  padding-top: 0.5rem;
}

#judgement-option-1-detail--page #main-content {
  position: relative;
  min-height: 100vh;
}
@media only screen and (max-width: 1000px) {
  #judgement-option-1-detail--page #content-body {
    display: block;
  }
  #judgement-option-1-detail--page #main-content {
    width: 100%;
  }
}

#judgement-option-1-detail--page #comment-icon {
  position: absolute;
  top: 13px;
  right: 24px;
}

#judgement-option-1-detail--page #case-info {
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
  margin: 4rem 0;
  padding: 0 10%;
}

#judgement-option-1-detail--page #case-title {
  color: #69c448;
  margin-bottom: 1.25rem;
}

#judgement-option-1-detail--page .yellow {
  color: #cebf09;
}

#judgement-option-1-detail--page #case-number {
  color: #101010;
  margin-bottom: 0.375rem;
}

#judgement-option-1-detail--page #court-type {
  /* margin-top: 2.25rem; */
  text-transform: uppercase;
}

#judgement-option-1-detail--page #case-id {
  font-size: 1.125rem;
  font-weight: 400;
  margin-top: 2rem;
  margin-bottom: 2.75rem;
}

#judgement-option-1-detail--page .green-text {
  color: #27750a;
  font-weight: 400;
}

#judgement-option-1-detail--page #appelent {
  margin-top: 1.8125rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

#judgement-option-1-detail--page .other-appelent {
  font-size: 1.125rem;
  font-weight: 400;
  margin-bottom: 0.5rem;
}

#judgement-option-1-detail--page #last-appelent,
#judgement-option-1-detail--page #last-respondent {
  margin-bottom: 1.5rem;
}

#judgement-option-1-detail--page #respondent {
  margin-top: 1.8125rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

#judgement-option-1-detail--page .other-respondent {
  font-size: 1.125rem;
  font-weight: 400;
  margin-bottom: 0.5rem;
}

#judgement-option-1-detail--page #ending-text {
  font-weight: 400;
}

#judgement-option-1-detail--page #case-info-sections {
  padding: 0 2.25rem;
}

#judgement-option-1-detail--page .section-title {
  padding: 0.75rem 1.125rem;
  background-color: #f6f6f6;
  color: #27750a;
  font-size: 1.5rem;
}

#judgement-option-1-detail--page #justices-section .section-title p {
  display: inline-block;
  border-bottom: 4px solid #cebf09;
  border-radius: 5px;
}

#judgement-option-1-detail--page #justices-section .section-body {
  margin: 3rem 0;
}

#judgement-option-1-detail--page #justices-section .section-body-row p {
  color: #000000;
  font-size: 1.5rem;
}

#judgement-option-1-detail--page .section-body .section-body-row .sub-text {
  color: #27750a;
  font-size: 1rem;
  font-weight: 600;
}

#judgement-option-1-detail--page .section-body-row {
  display: flex;
  justify-content: space-between;
  padding-bottom: 1.5rem;
}

#judgement-option-1-detail--page .section-title {
  margin-top: 2.5rem;
}

#judgement-option-1-detail--page .section-body {
  padding-top: 2.5rem;
  padding-bottom: 3.1825rem;
  font-size: 1.1rem;
  color: #000000;
  line-height: 1.9;
}

#judgement-option-1-detail--page #side-content {
  z-index: 88;
  position: sticky;
  overflow-y: auto;
  scrollbar-width: thin;
  font-size: 0.875rem;
  width: 26.5%;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

#judgement-option-1-detail--page #side-content::-webkit-scrollbar-track {
  box-shadow: none;
  -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.987);
  border-radius: 10px;
  background-color: #d8d8d8;
  overflow-x: auto;
}

#judgement-option-1-detail--page #side-content::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
  overflow-x: auto;
}

#judgement-option-1-detail--page #side-content::-webkit-scrollbar-thumb {
  box-shadow: none;
  -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
  background-color: #a0a0a0;
  overflow-x: auto;
}

#judgement-option-1-detail--page #side-content-wrapper {
  position: relative;
}

#judgement-option-1-detail--page #side-content-title {
  position: sticky;
  top: 0;
  background-color: #dadada;
  padding: 0.75rem 1.75rem;
  color: #101010;
  font-size: 1.125rem;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
}

#judgement-option-1-detail--page .ratio-decendi {
  padding: 1.375rem 1.75rem;
  border-bottom: 1px solid #dadada;
}

#judgement-option-1-detail--page .ratio-decendi a {
  color: #cebf09;
}

#judgement-option-1-detail--page .ratio-decendi-title {
  color: #69c448;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.rfj-link {
  margin-top: 20px !important;
  color: #cebf09 !important;
  text-decoration: underline !important;
  font-weight: 600;
}

.case-title-case {
  color: #69c448;
}

.yellow-vs {
  color: #cebf09;
}
.cr-link {
  color: #cebf09 !important;
  font-weight: 500;
}
/* END OF JUDGEMENT-OPTION-1-DETAIL--PAGE STYLES */

.tab-fea {
  min-width: 230px;
  max-width: 230px;
  text-align: center;
}

.filters-mobile {
  display: none;
}

@media (max-width: 1024px) {
  .desktop-only {
    display: none;
  }
  .filters-mobile {
    display: block;
  }
  .search-filter {
    width: 100%;
  }
  #side-content {
    display: none;
  }
}

@media (min-width: 200px) and (max-width: 700px) {
  .blo-ck {
    display: block;
  }
  .img-width {
    width: 150px;
  }
  .full-width {
    width: 100%;
  }
  .none {
    display: none;
  }
  .ml-reset {
    margin-left: 0px;
  }
  .sub-but {
    margin-left: 0;
    width: 100%;
    margin-top: 10px;
  }
  .ml-top {
    margin: 40px 10px;
  }
  .butt-top {
    margin-top: 10px;
  }
  .p-reset {
    padding: 0;
  }
  .faq {
    margin: 40px 20px;
  }
  .top-2 {
    margin-top: 20px;
  }
  .top {
    margin-top: 40px;
  }
  .top-3 {
    margin-top: 5px;
  }
  .book {
    max-width: 200px;
    margin: auto;
  }
  .reset-mx {
    margin-left: 0px;
    margin-right: 0px;
  }
  .center {
    margin: auto;
  }
  .x {
    display: block;
  }
  .form-box {
    padding: 0 10px;
  }
  .price-box {
    padding: 0px;
  }
  .wpp-box {
    padding: 40px 20px;
  }
  .about-link {
    overflow-x: scroll;
  }
  .m-x-8 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .res-head-txt {
    font-size: 1rem;
  }
  .hiw {
    background: #fff;
  }
  .m-x-auto {
    margin: 0px auto;
  }
  .hide-for-phone {
    display: block;
  }
  .show-mob-menu {
    display: flex;
    align-items: center;
  }
  .custom-form {
    width: 100%;
    padding: 0px 1.55rem;
  }
  .mob-but-text {
    font-size: 0.75rem;
  }
  .Collapsible__trigger {
    font-size: 14px;
  }
  .p-phone {
    padding: 0.7rem 2rem;
  }
  .date-res {
    display: block;
  }
  #judgement-option-1-detail--page #title {
    font-size: 1rem;
  }
  #judgement-option-1-detail--page #title-row {
    display: block;
  }
  #judgement-option-1-detail--page .btn {
    margin-top: 10px;
  }
  .desktop-only {
    display: none;
  }
}

#pc-head {
  display: flex;
  align-items: center;
  padding: 30px 20px;
  border-bottom: 1px solid #e5e5e5;
}

.pc-ti1 {
  display: flex;
  align-items: center;
}

.pc-ti2 {
  display: flex;
  margin-left: auto;
}

#pc-card > img {
  display: flex;
  margin-left: auto;
}
#pc-num {
  margin-top: 48px;
  margin-bottom: 24px;
  color: #fff;
  font-size: 18px;
}
#pc-card-foot {
  display: flex;
}
#pc-input-head {
  position: absolute;
  z-index: 1;
  margin-left: 20px;
  margin-top: 10px;
  color: #737a91;
  font-size: 14px;
}
#pc-input {
  border: 1px solid #dfe2e6 !important;
  box-sizing: border-box !important;
  border-radius: 8px !important;
  padding-top: 40px !important;
  padding-bottom: 15px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  width: 100%;
  outline: none !important;
  color: #111111;
  font-size: 18px;
  background-color: #fff;
}
#row-input {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  width: 100%;
}
#row-input > div {
  width: 100%;
}
#pc-button {
  max-width: 375px;
  margin: 40px auto;
}
#pc-form-sub {
  background: #27750a;
  border-radius: 5px;
  padding: 15px;
  margin: auto;
  width: 100%;
  font-size: 18px;
  color: #fff;
  outline: none !important;
  border: none !important;
  cursor: pointer;
}

@media (max-width: 425px) {
  #pc-head {
    display: block;
  }
  .pc-ti2 {
    margin-top: 20px;
  }
  #row-input {
    display: block;
  }
  #row-i-2 {
    margin-top: 20px;
  }
}

@media (min-width: 701px) and (max-width: 1022px) {
  .first {
    max-width: 280px;
  }
  .bock {
    display: block;
  }
  .full-w-idth {
    width: 100%;
  }
  .mi-d-d {
    margin: auto;
  }
  .book {
    width: 200px;
    margin: 40px auto;
  }
  .fifth {
    padding: 40px;
  }
  .no-ne {
    display: none;
  }
  .ml-r-eset {
    margin-left: 0px;
    margin-top: 10px;
  }
  .hiw {
    background: #fff;
  }
  .res-head-txt {
    font-size: 1.25rem;
  }
  .m-x-8 {
    margin-left: 1.75rem;
    margin-right: 1.75rem;
  }
  .sma-side {
    margin-left: 4rem;
  }
  .hide-for-phone {
    display: block;
  }
  .show-mob-menu {
    display: flex;
    align-items: center;
  }
  .date-res {
    display: block;
  }
}

@media (min-width: 1023px) and (max-width: 1200px) {
  .bo-ck {
    display: block;
  }
  .full-w-idth {
    width: 100%;
  }
  .book {
    width: 200px;
  }
  .n-one {
    display: none;
  }
  .hiw {
    background: #fff;
  }
  .date-res {
    display: block;
  }
}
