.auth-layout {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.auth-layout > .container {
  display: flex;
  min-height: 100vh;
}

.auth-layout > button:disabled {
  opacity: 0.7;
}

.auth-layout > #heading {
  margin: 15px 0 70px;
  color: #333333;
}

.auth-layout > h3 {
  color: #101010;
}

.auth-layout > .image {
  /* background-image: url('../img/auth.png'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: 55%;
  color: #fff;
  background-color: #c7edbb;
}

.auth-form {
  width: 100% !important;
}

/* .auth-layout > #content {
  height: 100%;
  width: 63.68877%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  margin-top: 80px;
  margin-bottom: 0 !important;
  background: none !important;
  box-shadow: none !important;
  text-align: center;
}

.auth-layout > #content img:first-child {
  height: 70px;
  margin-bottom: 30px;
}

.auth-layout > #content h1 {
  font-size: 2.25rem;
}

.auth-layout > #content div {
  font-size: 1.5rem;
}

.auth-layout > div#social {
  font-size: 1rem;
  margin-top: 1rem;
  display: flex;
  align-items: center;
}

.auth-layout > #social img {
  margin-left: 0.75rem;
}

.auth-layout > .text {
  height: 100vh;
  display: flex;
  flex-direction: column;
  margin: 0px auto;
  width: 45%;
}

.auth-layout > .text > div {
  max-width: 550px;
  width: 100%;
  margin: 80px auto 0;
} */

.auth-form > #input-wrapper > .input {
  position: relative;
  padding: 0.75rem 0;
  padding-bottom: 0;
}

.auth-form > #input-wrapper > .input::after {
  content: none !important;
}

.auth-form > #input-wrapper > p.l {
  position: absolute;
  top: 0;
  left: 30px;
  background-color: #fff;
  padding: 0.1rem 0.3rem;
  font-size: 1.125rem;
  opacity: 0;
}

.auth-form > #input-wrapper > .input > input:not([type="checkbox"]) {
  width: 100%;
  height: 3.75rem;
  padding: 0 2rem;
  border: 1px solid #27750a;
  border-radius: 5px;
  position: relative;
}

.auth-form
  > #input-wrapper
  > .input
  > input:not([type="checkbox"])::placeholder,
.auth-form > #input-wrapper > .input > p.l {
  color: #27750a;
  font-weight: 600;
  font-size: 1rem;
}

.auth-form
  > #input-wrapper
  > .input
  > input:not([type="checkbox"]):focus::placeholder {
  opacity: 0;
}

.auth-form > #input-wrapper > .input > input:focus + p.l {
  opacity: 1;
}

.auth-form > #input-wrapper > .input > input:valid + p.l {
  opacity: 1;
}

/* change */
.auth-form > .grid-input > #input-wrapper > .input {
  position: relative;
  padding: 0.75rem 0;
  padding-bottom: 0;
}

.auth-form > .grid-input > #input-wrapper > .input::after {
  content: none !important;
}

.auth-form > .grid-input > #input-wrapper > p.l {
  position: absolute;
  top: 0;
  left: 30px;
  background-color: #fff;
  padding: 0.1rem 0.3rem;
  font-size: 1.125rem;
  opacity: 0;
}

.auth-form
  > .grid-input
  > #input-wrapper
  > .input
  > input:not([type="checkbox"]) {
  width: 100%;
  height: 3.75rem;
  padding: 0 2rem;
  border: 1px solid #27750a;
  border-radius: 5px;
  position: relative;
}

.auth-form > .grid-input > #input-wrapper > .input > p.l {
  color: #27750a;
  font-weight: 600;
  font-size: 1rem;
}

.auth-form
  > .grid-input
  > #input-wrapper
  > .input
  > input:not([type="checkbox"]):focus::placeholder {
  opacity: 0;
}

.auth-form > .grid-input > #input-wrapper > .input > input:focus + p.l {
  opacity: 1;
}

.auth-form > .grid-input > #input-wrapper > .input > input:valid + p.l {
  opacity: 1;
}

/* input[type='email'] {
	margin-bottom: 2.5625rem;
} */

.auth-form > #input-wrapper > #checkbox {
  margin: 1.5rem 0;
}

.auth-form > #input-wrapper > #checkbox input {
  margin-right: 1rem;
}

.auth-form > button {
  width: 100%;
  padding: 1.125rem 0;
  margin-bottom: 0.625rem;
  background-color: #27750a;
  color: #fff;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
}

.auth-signup {
  color: #cebf09;
  text-decoration: none;
  font-weight: 600;
}

/* .auth-layout > @media only screen and (max-width: 1000px) {
  .container {
    flex-direction: column;
  }

  .container > * {
    width: 100%;
  }

  .text,
  #content {
    padding: 0;
    width: 85%;
    margin: auto;
    height: 100%;
  }

  .text {
    margin-top: 2rem;
    margin-bottom: 5rem;
  }

  .image {
    background-image: url("../img/Frame434.svg");
    height: 60vh;
    display: none;
  }

  #content {
    padding: 1.5rem 0;
    text-align: center;
  }

  #social {
    justify-content: center;
  }

  input[type="email"] {
    margin-bottom: 1.25rem;
  }
} */
