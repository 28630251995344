.app {
  height: 100%;
  background-color: #f7f7f7;
}
.visible {
  visibility: visible;
}
.invisible {
  visibility: hidden;
}
/* ------------- Sidebar ----------------*/
.sidebar {
  position: fixed;
  display: none;
  opacity: 1;
  width: 17rem;
  height: 100%;
  margin-left: -2rem;
  top: 0;
  z-index: 1;
  overflow: hidden;
  background-color: #fff;
  -webkit-transform: translateZ(0);
  visibility: visible;
  -webkit-backface-visibility: hidden;
  -webkit-box-shadow: 2px 0px 10px 5px rgba(0, 0, 0, 0.11);
  -moz-box-shadow: 2px 0px 10px 5px rgba(0, 0, 0, 0.11);
  box-shadow: 2px 0px 10px 5px rgba(0, 0, 0, 0.11);
}
.sidebar header {
  background-color: #fff;
  width: 100%;
  display: block;
  padding: 0.5em 1em;
}
.sidebar__logo {
  width: 230px;
}
/* ------------- Sidebar Nav ----------------*/
.sidebar-nav {
  position: fixed;
  height: 100%;
  font-weight: 400;
  font-size: 1.1em;
  overflow: auto;
  padding: 10px;
  -webkit-overflow-scrolling: touch;
  width: 100%;
}
.sidebar-nav a {
  display: block;
  padding: 20px 20px 20px 15px;
  color: black;
}
.sidebar-nav a .nav-icons {
  font-size: 1.1em;
  position: relative;
  top: 3px;
  right: 15px;
  color: rgba(0, 0, 0, 0.4);
}
.sidebar-nav a:hover {
  background-color: green;
  color: white;
}

.sidebar-nav a.diff {
  border: 2px solid #27750a;
  color: #27750a;
}

.sidebar-nav a.diff:hover {
  background-color: green;
  color: white;
}

.nav-off {
  margin-left: 20px;
}
.nav-off li {
  margin-left: 0;
  padding-left: 0;
  display: inline-block;
  width: 100%;
  padding: 0 1em;
}
.nav-off li:hover {
  background-color: #eee;
}
.nav-off li a {
  color: #19222a;
  font-size: 0.75em;
  padding: 1.05em 0em;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: none !important;
}
.sidebar-foot {
  position: absolute;
  bottom: 0;
  background-color: #eee;
  z-index: 20;
  width: 100%;
  padding: 1em 0;
  text-align: center;
  cursor: pointer;
}
.main {
  margin-left: 17em;
  overflow: hidden;
}
@media (max-width: 960px) {
  .main {
    margin-left: 0px;
  }
}
.top-nav {
  padding: 20px 0px 0px 0px;
  border-bottom: 1px solid #eee;
  display: flex;
  position: relative;
  justify-content: space-around;
}
.top-nav span:first-child {
  display: flex;
}
.top-nav span:last-child {
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  top: 5px;
  font-size: 20px;
}
.top-nav span:last-child .notification {
  position: relative;
  top: 3px;
  right: 8px;
}
.top-nav__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 22px;
}
.top-nav__content--button {
  cursor: pointer;
  visibility: hidden;
}
.breadcrum {
  background-color: #fff;
}
.breadcrum__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 22px;
}
.dropdown {
  display: inline-block;
  position: relative;
}
.dropdown__button {
  display: inline-block;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
  white-space: nowrap;
  margin-right: 30px;
}
.dropdown__button:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid black;
}
.dropdown__button--img {
  width: 40px;
  margin-right: 5px;
}
.dropdown__input {
  display: none;
}
.dropdown__menu {
  position: absolute;
  top: 123%;
  right: 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0;
  margin: 2px 0 0 0;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  list-style-type: none;
}
.dropdown__menu li {
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  white-space: nowrap;
}
.dropdown__menu li:hover {
  background-color: #f6f6f6;
}
.dropdown__input + .dropdown__menu {
  display: none;
}
.dropdown__input:checked + .dropdown__menu {
  display: block;
}
.textarea-style {
  height: 300px;
  padding: 30px 0px 50px 0px;
}
.error {
  color: red;
}
@media (max-width: 960px) {
  .sidebar {
    display: none;
  }
  .close {
    display: none !important;
  }
  .open {
    display: block !important;
  }
  .visible {
    visibility: visible;
  }
  .invisible {
    visibility: hidden;
  }

  .nav_menu_links {
    display: none !important;
  }
}
