.di--img{
    background: url(https://res.cloudinary.com/dudhmrdin/image/upload/v1608336639/Rectangle_57_2_lisbhy.png);
    padding: 80px 20px;
    border-radius: 5px;
}
.di-head{
    font-size: 28px;
    font-weight: bold;
    text-align: center;
    color: #CEBF09;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    letter-spacing: -0.02em;
  }
.w--bet-box{
    background: #27750A;
    padding: 20px;
    margin: 20px 0px;
    display: flex;
    align-items: center;
}
.w--bet {
    margin: 0px auto;  
}
.w--bet > p{
    color: white;
    font-weight: 600;
    margin: 10px;
}
.first-act{
    color: #CEBF09 !important;
}
.ml-auto{
  margin-left: auto !important;
}
.align-center{
  align-items: center;
}
.flex{
    display: flex;
}
.di--sub{
    margin: 40px 0px;
    display: flex;
    align-items: center;
}
.di--sub-big{
    padding: 10px;
    background: #FFFFFF;
    border-radius: 9999px;
    width: 70px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.di--sub-big > p{
    font-weight: bold;
    font-size: 36px;
    color: #CEBF09;
    text-align: center;
}
.di--sub-text{
    color: #928989;
    font-weight: bold;
    font-size: 24px;
    margin-left: 20px;
}
.di--content{
    background: #FFFFFF;
    padding: 30px 50px;
}
.di-con-wrap{
    display: flex;
    margin: 40px 0px;
}
.di-con-textbox{
    margin: 0px 20px;
    width: 100%;
}
.di-con-head{
    color: #101010;
    font-weight: 600;
    font-size: 24px;
}
.di-con-text{
    color: #101010;
    font-size: 18px;
}
button{
    border: none;
    background: transparent;
}
.pagination {
    display: inline-block;
}
.pagination a{
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid #27750A;
    margin: 0 5px;
    font-size: 12px;
    text-align: center;
    letter-spacing: 0.1em;
    color: #000000;
}

@media (min-width: 300px) and (max-width: 500px) {
    .bock{
        display: block;
    }
    .bock > button{
        margin-top: 20px;
    }
    .di--content{
        background: #FFFFFF;
        padding: 20px 20px;
    }
}

@media (min-width: 300px) and (max-width: 1000px) {
    .w--bet{
      width: 100%;
      overflow-x: scroll;
    }
}

@media (max-width: 960px) {
    .nav-menu-links.flex {
      display: none !important;
    }

    .logo-header {
        margin-left: 6rem;
    }
}